<template>
  <div class="dynamic-input-wrapper">
    <!-- checkbox -->
    <div
      class="pl-2 block-check-border margin-check-immo"
      v-if="
        input.type_options === 'checkbox' && input.type === 'multiple_select'
      "
    >
      <div class="label-champ">{{ input.label }}</div>
      <v-checkbox
        label="Tous"
        v-model="checkAll"
        @change="handlecheckAll()"
        color="#704ad1"
        :value="true"
        :true-value="true"
        :false-value="false"
      ></v-checkbox>
      <div v-for="(item, index) in options" :key="index" class="ml-3 pl-3">
        <v-checkbox
          class="input-checkbox"
          :label="item.label"
          v-model="item.checked"
          @change="itemToCheck(item)"
          color="#704ad1"
          :value="true"
          :true-value="true"
          :false-value="false"
        ></v-checkbox>
      </div>
    </div>
    <!--  SELECT ONE -->
    <div
      v-else-if="input.type_options === 'one_select' && input.type === 'radio'"
    >
      <v-autocomplete
        :disabled="input.disabledInput"
        :disable-lookup="true"
        :clearable="true"
        color="#5C2DD3"
        item-color="#5C2DD3"
        v-model="valueOptions"
        :value="valueOptions"
        @input="handleMultiselectSet"
        @click:clear="handleSelectSetsClear"
        @click="appelRouteDynamic"
        :items="options"
        :multiple="selectedOptions"
        :placeholder="input.placeholder || '-'"
        :persistent-placeholder="true"
        return-object
        :chips="selectedOptions ? true : false"
        :deletable-chips="selectedOptions ? true : false"
        :small-chips="selectedOptions ? true : false"
        item-text="label"
        :label="input.label"
        dense
        class="mb-1"
        :class="{
          'input-select-height-standard': !input.styleInputStandard
        }"
        item-value="id"
        :auto-focus="false"
        no-data-text="Aucun option trouvée"
        outlined
        :menu-props="{
          bottom: true,
          offsetY: true
        }"
      >
      </v-autocomplete>
    </div>
    <!-- input -->
    <v-text-field
      :disabled="input.disabledInput"
      v-else-if="input.type === 'string'"
      :label="input.label"
      :persistent-placeholder="true"
      dense
      outlined
      v-model="valueText"
      color="#5C2DD3"
      :type="input.type"
      :name="input.name"
      :value="value"
      :placeholder="input.placeholder || '-'"
      :class="{ 'input-text-height-standard': !input.styleInputStandard }"
      v-debounce:400="handleInput"
    >
    </v-text-field>
    <!-- text(textarea) -->
    <v-textarea
      :disabled="input.disabledInput"
      v-else-if="input.type === 'text'"
      :class="input.class"
      :placeholder="input.placeholder || '-'"
      :name="input.name"
      :value="value"
      v-debounce:400="handleInput"
      outlined
      :label="input.label"
      v-model="valueText"
      :persistent-placeholder="true"
      rows="3"
      color="#5C2DD3"
      class="text-area-dynamique"
    ></v-textarea>
    <!-- integer|double(input:number) -->
    <v-text-field
      :disabled="input.disabledInput"
      :placeholder="input.placeholder || '-'"
      v-else-if="
        input.type === 'integer' ||
          input.type === 'double' ||
          input.type === 'number'
      "
      :label="input.label"
      :persistent-placeholder="true"
      dense
      outlined
      color="#5C2DD3"
      :name="input.name"
      :min="input.min"
      :max="input.max"
      :step="input.step"
      :type="input.type"
      v-model="valueText"
      :value="value"
      @click="handleInputNumber"
      v-debounce:400="handleInput"
      :class="{ 'input-text-height-standard': !input.styleInputStandard }"
    >
    </v-text-field>
    <!-- radio OUI / NON -->
    <v-radio-group
      :label="input.label"
      v-model="valueOptions"
      row
      v-else-if="input.type === 'radio_oui_non'"
      class="radio-dynamique"
      :class="{ 'option-sup-3': options && options.length >= 3 }"
      hide-details
    >
      <v-radio
        :disabled="input.disabledInput"
        v-for="(n, index) in options"
        :key="index"
        :label="n.text ? n.text : 'vide'"
        :value="n.value"
        @click="handleRadio(n.value)"
        class="radio-btn"
        color="#5C2DD3"
      ></v-radio>
    </v-radio-group>
    <!-- radio -->
    <div
      class="pl-2 block-check-border margin-check-immo"
      v-else-if="input.type === 'radio'"
    >
      <div class="label-champ">{{ input.label }}</div>
      <v-radio-group
        v-model="valueOptions"
        row
        class="radio-dynamique style-immo-radio"
        :class="{
          'option-sup-3': options && options.length >= 3
        }"
        hide-details
      >
        <v-radio
          v-for="(n, index) in options"
          :key="index"
          :label="n.text ? n.text : 'vide'"
          :value="n.value"
          @click="handleRadio(n.value)"
          class="radio-btn"
          color="#5C2DD3"
          :disabled="input.disabledInput"
        ></v-radio>
      </v-radio-group>
    </div>
    <!-- multiple_list -->
    <div v-else-if="input.type === 'multiple_list'">
      <div class="flex align-center">
        <div
          class="flex"
          v-if="input.name == 'phones' && options && options.length"
        >
          <v-text-field
            :disabled="input.disabledInput"
            :label="input.label"
            :persistent-placeholder="true"
            dense
            outlined
            color="#5C2DD3"
            :type="input.type"
            :name="input.name"
            :value="options[0].number"
            :placeholder="input.placeholder || '-'"
            @input="handleInputList($event, 'numberPhone', 'phones')"
            :class="{ 'input-text-height-standard': !input.styleInputStandard }"
          >
          </v-text-field>
        </div>
        <div
          class="flex"
          v-if="input.name == 'emails' && options && options.length"
        >
          <v-text-field
            :disabled="input.disabledInput"
            :label="input.label"
            :persistent-placeholder="true"
            dense
            outlined
            color="#5C2DD3"
            :type="input.type"
            :name="input.name"
            :value="options[0].email"
            @input="handleInputList($event, 'email', 'emails')"
            :placeholder="input.placeholder || '-'"
            :class="{ 'input-text-height-standard': !input.styleInputStandard }"
          >
          </v-text-field>
        </div>
      </div>
    </div>

    <!-- enum(select) -->
    <v-autocomplete
      :disabled="input.disabledInput"
      :clearable="true"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-else-if="input.type === 'enum'"
      :value="computedEnumValue"
      @input="handleMultiselectEnum"
      :items="input.options || []"
      :multiple="false"
      :placeholder="input.placeholder || '-'"
      :persistent-placeholder="true"
      required
      return-object
      item-text="label"
      :label="input.label"
      dense
      class="mb-1"
      :class="{ 'input-select-height-standard': !input.styleInputStandard }"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>

    <!-- set(multiselect) -->
    <v-autocomplete
      :clearable="true"
      v-else-if="input.type === 'multiple_select'"
      color="#5C2DD3"
      item-color="#5C2DD3"
      :disable-lookup="true"
      :disabled="input.disabledInput ? input.disabledInput : isUpdating"
      v-model="valueOptions"
      :value="valueOptions"
      @input="handleMultiselectSet"
      item-disabled="disabled"
      :items="options"
      :multiple="selectedOptions"
      :placeholder="input.placeholder || '-'"
      :loading="getRouteDynamicProcessing || isUpdating"
      :persistent-placeholder="true"
      return-object
      :chips="selectedOptions ? true : false"
      :deletable-chips="selectedOptions ? true : false"
      :small-chips="selectedOptions ? true : false"
      item-text="label"
      :label="input.label"
      dense
      class="mb-1 input-chips-select-immo"
      :class="{ 'input-select-height-standard': !input.styleInputStandard }"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>
    <div v-else-if="input.type === 'tag'">
      <v-combobox
        :ref="'ref-' + input.field_id"
        outlined
        :disable-lookup="true"
        item-disabled="disabled"
        :persistent-placeholder="true"
        color="#5C2DD3"
        :error="errorMessages[input.field_id] ? true : false"
        :loading="getCustomFieldsLoading"
        :disabled="getCustomFieldsLoading"
        item-color="#5C2DD3"
        v-model="valueTags"
        class="input-combobox"
        :class="{
          'input-select-height-standard': !input.styleInputStandard
        }"
        :items="itemsTags || []"
        hide-selected
        :label="input.label"
        small-chips
        multiple
        :filter="filter"
        :placeholder="
          itemsTags && itemsTags.length
            ? $t('msgPlaceholderCombobox')
            : $t('add_new_tag')
        "
        :hide-no-data="!search"
        :search-input.sync="search"
        @input="handleChangeCombobox"
        :menu-props="{
          maxHeight: '116px',
          transition: 'my-custom-transition',
          bottom: false,
          top: true
        }"
      >
        <template v-slot:no-data>
          <v-list-item>
            <span class="subheading mr-1">{{ $t('create') }}</span>
            <v-chip color="#5C2DD3" text-color="white" small>
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, selected, index }">
          <v-chip
            v-if="item === Object(item) && index <= 1"
            v-bind="attrs"
            color="#5C2DD3"
            text-color="white"
            :input-value="selected"
            label
            small
          >
            <span class="pr-2">
              {{ item.text }}
            </span>
            <v-icon small @click.prevent.stop="deleteItemTags(item)">
              $delete
            </v-icon>
          </v-chip>
          <span v-if="index == 2">
            (+{{ valueTags.length - 2 }} {{ $t('others') }})
          </span>
        </template>
        <template v-slot:item="{ item }">
          <v-text-field
            class="text-input-combobox"
            :value="JSON.parse(item).text"
            autofocus
            flat
            readonly
            background-color="transparent"
            hide-details
            solo
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-list-item-action class="action-btn-delete-combobox">
            <v-btn
              icon
              :disabled="getCustomFieldsLoading"
              @click.stop.prevent="
                deletedChipCombobox($event, JSON.parse(item).id)
              "
            >
              <v-icon class="font-sz-20" small>mdi-trash-can-outline </v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-combobox>
      <div
        v-if="
          getCustomFieldsError &&
            !getCustomFieldsLoading &&
            errorMessages[input.field_id]
        "
        class="error-msg"
      >
        <ul v-if="Array.isArray(errorMessages[input.field_id])">
          <li v-for="(e, index) in errorMessages[input.field_id]" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ errorMessages[input.field_id] }}</span>
      </div>
    </div>

    <!-- table -->
    <v-autocomplete
      :disabled="input.disabledInput"
      :clearable="true"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-else-if="input.type === 'table'"
      :value="computedTableValue"
      @input="handleMultiselectTable"
      :items="input.options || []"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :show-labels="false"
      :placeholder="input.placeholder || '-'"
      :persistent-placeholder="true"
      required
      return-object
      item-text="label"
      :label="input.label"
      dense
      class="mb-1"
      :class="{ 'input-select-height-standard': !input.styleInputStandard }"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>
    <!-- date -->
    <v-menu
      v-else-if="input.type === 'date'"
      :close-on-content-click="false"
      offset-y
      content-class="input-date-dynamique custom-menu-position"
      min-width="288px !important"
      max-width="288px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          :value="selectedDate"
          :label="input.label"
          outlined
          readonly
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          @click:clear="handleDatePickerClear"
          prepend-inner-icon="mdi-calendar"
          color="#5C2DD3"
          class="input-date-height-standard"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="selectedDate"
        @input="handleDatePicker"
        :value="selectedDate"
        no-title
        locale="fr"
        color="#5C2DD3"
        item-color="#5C2DD3"
        :disabled="input.disabledInput"
      >
      </v-date-picker>
    </v-menu>
    <!-- daterange -->
    <v-menu
      v-else-if="input.type === 'daterange'"
      :close-on-content-click="false"
      offset-y
      min-width="288px !important"
      content-class="input-date-dynamique custom-menu-position"
      max-width="288px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          :value="selectedDate"
          :label="input.label"
          outlined
          readonly
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-calendar"
          @click:clear="handleDatePickerClear"
          color="#5C2DD3"
          class="input-date-height-standard"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="selectedDate"
        @input="handleDatePickerRange"
        :value="selectedDate"
        no-title
        locale="fr"
        color="#5C2DD3"
        item-color="#5C2DD3"
        range
        :disabled="input.disabledInput"
      >
      </v-date-picker>
    </v-menu>
    <!-- time -->

    <vue-ctk-date-time-picker
      v-else-if="input.type === 'time'"
      v-model="selectedTime"
      color="#45148F"
      :inline="false"
      inputSize="sm"
      buttonColor="#45148F"
      locale="fr"
      :label="input.label + 'test'"
      class="custom-ctk-component"
      format="HH:mm"
      formatted="HH:mm"
      :only-time="true"
      :no-button="true"
      @input="appelTimePicker()"
      :rules="['required']"
      :no-value-to-custom-elem="true"
    >
      <v-text-field
        ref="dateDynamicImmo"
        v-model="selectedTime"
        :label="input.label"
        outlined
        readonly
        dense
        clearable
        v-on="on"
        v-bind="attrs"
        :persistent-placeholder="true"
        :placeholder="input.placeholder || '-'"
        prepend-inner-icon="mdi-clock-time-four-outline"
        color="#5C2DD3"
        @click:clear="selectedTime = null"
        class="input-date-height-standard"
      ></v-text-field>
    </vue-ctk-date-time-picker>

    <!-- <v-menu
      v-else-if="input.type === 'time'"
      :close-on-content-click="true"
      offset-y
      class="input-date-dynamique"
      min-width="288px !important"
      max-width="288px !important"
      @input="closeMenuTime"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedTime"
          :value="selectedTime"
          :label="input.label"
          outlined
          readonly
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-clock-time-four-outline"
          color="#5C2DD3"
          @click="appelTimePicker"
          @click:clear="handleDatePickerClear"
          class="input-date-height-standard"
        ></v-text-field>
      </template>

      <v-time-picker
        v-model="selectedTime"
        :value="selectedTime"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>
    </v-menu> -->

    <!-- timerange -->
    <v-menu
      v-else-if="input.type === 'timerange'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedTimeRange"
          :value="selectedTime"
          :label="input.label"
          outlined
          readonly
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-clock-time-four-outline"
          color="#5C2DD3"
          @click:clear="handleDatePickerClear"
          class="input-date-height-standard"
        ></v-text-field>
      </template>

      <v-time-picker
        v-model="selectedTimeStart"
        :value="selectedTimeStart"
        @input="handleTimePickerRange"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>

      <v-time-picker
        v-model="selectedTimeEnd"
        :value="selectedTimeEnd"
        @input="handleTimePickerRange"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>
    </v-menu>
    <!-- datetime -->
    <vue-ctk-date-time-picker
      v-else-if="input.type === 'datetime'"
      v-model="selectedDateTime"
      color="#45148F"
      :inline="false"
      :buttonNowTranslation="'Today'"
      inputSize="sm"
      buttonColor="#45148F"
      locale="fr"
      :label="input.label"
      class="custom-ctk-component"
      format="YYYY-MM-DD HH:mm"
      :no-value-to-custom-elem="true"
      @input="checkRulesDate('dateTimeDynamicImmo')"
    >
      <v-text-field
        ref="dateTimeDynamicImmo"
        v-model="selectedDateTime"
        :label="input.label"
        @click:clear="selectedDateTime = null"
        outlined
        readonly
        dense
        clearable
        v-on="on"
        v-bind="attrs"
        :persistent-placeholder="true"
        :placeholder="input.placeholder || '-'"
        prepend-inner-icon="mdi-calendar"
        color="#5C2DD3"
        class="input-date-height-standard"
      ></v-text-field>
    </vue-ctk-date-time-picker>
    <!-- <v-menu
      v-else-if="input.type === 'datetime'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDateTime"
          :value="selectedDateTime"
          :label="input.label"
          @click:clear="
            handleResetFilter(
              'selectedDateTime',
              'selectedDateTimeDate',
              'selectedDateTimeTime'
            )
          "
          outlined
          readonly
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-calendar"
          color="#5C2DD3"
          class="input-date-height-standard"
        ></v-text-field>
      </template>
      <v-card>
        <v-card-text>
          <v-date-picker
            value-type="format"
            format="YYYY-MM-DD HH:mm"
            :value="selectedDateTimeDate"
            no-title
            v-model="selectedDateTimeDate"
            :class="input.class"
            locale="fr"
            :disabled="input.disabledInput"
            @input="handleDateTimePicker"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-date-picker>
          <v-time-picker
            class="time-dynamique"
            v-model="selectedDateTimeTime"
            :value="selectedDateTimeTime"
            @input="handleDateTimePicker"
            color="#5C2DD3"
            item-color="#5C2DD3"
            format="24hr"
          ></v-time-picker>
        </v-card-text>
      </v-card>
    </v-menu> -->
    <div v-else>
      <v-text-field
        :disabled="input.disabledInput"
        :label="input.label"
        :persistent-placeholder="true"
        v-model="value"
        dense
        outlined
        color="#5C2DD3"
        :type="input.type"
        :name="input.name"
        :value="value"
        @input="handleInput"
        :placeholder="input.placeholder || '-'"
        :class="{ 'input-text-height-standard': !input.styleInputStandard }"
      >
      </v-text-field>
    </div>
    <!-- end input type if -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import * as moment from 'moment'
import { debounce } from 'lodash'
moment.locale('fr')
/**
 * @Module Dynamic Input - all supported inputs
 * @param {Object} input - an input configuration
 * @param {String} input.type(Required) - The type of input supported inputtypes(text,email,password,hidden,tel,date,number,select,textarea)
 * @param {String} input.name(Required) - The name of the input field
 * @param {any} input.value(Optional) - The value of the input field
 * @param {String} input.label(Optional) - The label of the input field
 * @param {String} input.class(Optional) - The Css class(es) of the input field
 *
 * **Input specific parameters**
 * @param input.min - minimum value of the input field(date,number)
 * @param input.max - maximum value of the input field(date,number)
 * @param {Number} input.step - increments of the value on increase & decrease(number)
 * @param {Array} options - al the options in a selectbox
 */
export default {
  name: 'dynamic-input-info-immo',
  components: {},
  props: {
    error: {
      required: false
    },
    input: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    formatDate: {
      required: false
    }
  },
  data() {
    return {
      attrs: {}, // Ajout de la propriété attrs
      on: {}, // Ajout de la propriété on
      options: [],
      selectedOptions: false,
      valueOptions: null,
      selectedDate: null,
      selectedTime: null,
      selectedDateTimeDate: null,
      selectedDateTimeTime: null,
      selectedDateTime: null,
      valueText: null,
      valueSelect: null,
      valueDynamic: this.value,
      selectedTimeRange: null,
      selectedTimeStart: null,
      selectedTimeEnd: null,
      checkAll: false,
      tableItemCheck: [],
      isUpdating: false,
      valueTags: [],
      itemsTags: [],
      search: null,
      errorMessages: []
    }
  },
  methods: {
    ...mapActions([
      'fetchProjectRouteDynamic',
      'deleteCustomFieldsOptions',
      'fetchCustomFieldsByTypeProjet',
      'addCustomFieldsOptions',
      'resetErrorCustomField'
    ]),
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1000)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    // START TAGS
    handleErrors(input, errorMessage) {
      this.errorMessages[input] = errorMessage
    },
    async appelRouteTableTags() {
      this.handleErrors(this.input.field_id, null)
      this.valueTags = this.valueTags.filter(item => item.id != null)
      await this.fetchCustomFieldsByTypeProjet({
        field_id: this.input.field_id,
        type_projet_id: localStorage.getItem('typeProject'),
        projet_id: this.input.projet_id
      })
    },
    deleteItemTags(item) {
      this.valueTags = this.valueTags.filter(tag => tag !== item)
      const existingLabels = this.valueTags.map(tag => tag.text.trim())
      this.itemsTags = this.getOptionsFieldTag
        .filter(v => !existingLabels.includes(v.label.trim()))
        .map(v => JSON.stringify({ text: v.label, id: v.id, color: '#5C2DD3' }))
      this.$nextTick(() => {
        const stringIds = this.valueTags
          .filter(tag => tag.text !== null)
          .map(tag => tag.text)

        this.$emit('save', stringIds)
        this.$emit('disabled')
      })
    },
    refreshTableTags() {
      this.itemsTags = this.getOptionsFieldTag
        .filter(v => !this.valueTags.find(tag => tag.id === v.id))
        .map(v => JSON.stringify({ text: v.label, id: v.id, color: '#5C2DD3' }))
    },
    async fetchItemTags() {
      this.handleErrors(this.input.field_id, null)
      this.resetErrorCustomField()
      await this.appelRouteTableTags().then(() => {
        if (this.getOptionsFieldTag && this.getOptionsFieldTag.length) {
          if (this.value && this.value.length) {
            // Vérifie si chaque élément de valueTags correspond à un nom dans la table
            const allValuesMatch = this.value.every(tag =>
              this.getOptionsFieldTag.some(item => item.label === tag)
            )

            if (allValuesMatch) {
              this.valueTags = this.value
                .map(tag => {
                  const matchingOption = this.getOptionsFieldTag.find(
                    item => item.label === tag
                  )
                  if (matchingOption) {
                    return {
                      id: matchingOption.id,
                      text: matchingOption.label,
                      color: '#5C2DD3'
                    }
                  } else {
                    return null // ou une valeur par défaut si aucune correspondance n'est trouvée
                  }
                })
                .filter(Boolean) // supprimer les éléments nuls si nécessaire
            }
          }
        }
      })

      this.refreshTableTags()
    },
    handleChangeCombobox(item) {
      this.handleErrors(this.input.field_id, null)
      this.resetErrorCustomField()
      if (item) {
        let containsString = false
        let containsObject = false

        // Vérifier si le tableau contient une chaîne de texte simple et/ou un objet JSON
        item.forEach(element => {
          if (typeof element === 'string') {
            try {
              const parsedElement = JSON.parse(element)
              if (typeof parsedElement === 'object' && parsedElement !== null) {
                containsObject = true
              } else {
                containsString = true
              }
            } catch (error) {
              containsString = true // La chaîne n'est pas une chaîne JSON valide, donc c'est une chaîne simple
            }
          } else if (typeof element === 'object') {
            containsObject = true
          }
        })
        // Si le tableau contient uniquement des chaînes de texte simples
        if (containsString && !containsObject) {
          // Traiter les chaînes de texte simples ici
          item.forEach(text => {
            // Trouver l'index de l'élément à supprimer
            const index = this.valueTags.indexOf(text)
            if (index !== -1) {
              // Supprimer l'élément à l'index trouvé
              this.valueTags.splice(index, 1)
              // Ajouter le nouvel objet à cet index
              this.valueTags.splice(index, 0, {
                color: '#5C2DD3',
                id: null,
                text: text
              })
            }
          })
        }
        // Si le tableau contient uniquement des objets JSON
        else if (!containsString && containsObject) {
          // Traiter les objets JSON ici
          this.valueTags = item
            .map(element => {
              // Si l'élément est déjà un objet, le retourner tel quel
              if (typeof element == 'object') {
                return element
              }
              // Si l'élément est une chaîne JSON, le convertir en objet
              else if (typeof element == 'string') {
                try {
                  return JSON.parse(element)
                } catch (error) {
                  return null // Gérer l'erreur si la chaîne JSON n'est pas valide
                }
              }
            })
            .filter(Boolean)

          this.refreshTableTags()
        }
        // Si le tableau contient à la fois des chaînes de texte simples et des objets JSON
        else if (containsString && containsObject) {
          // Gérer le cas d'erreur ici
          let stringsIndex = item.findIndex(
            element => typeof element === 'string'
          )

          if (stringsIndex !== -1) {
            // Créez un nouvel objet avec les propriétés spécifiées
            let newObj = {
              text: item[stringsIndex], // Utilisez la chaîne de texte comme valeur de la propriété 'text'
              color: '#5C2DD3', // Définissez la couleur
              id: null // Définissez l'ID comme null
            }

            // Supprimez la chaîne de texte du tableau
            item.splice(stringsIndex, 1)

            // Ajoutez le nouvel objet au tableau
            item.push(newObj)
          }
        }
      }
      this.$nextTick(async () => {
        await this.saveCombobox()
      })
    },
    async addCustomFieldCombobox(elementObject) {
      const response = await this.addCustomFieldsOptions({
        label: elementObject && elementObject.text ? elementObject.text : '',
        field_id: this.input.field_id,
        type_projet_id: localStorage.getItem('typeProject'),
        projet_id: this.input.projet_id
      })
      if (response) {
        // refresh list option tags
        await this.fetchCustomFieldsByTypeProjet({
          field_id: this.input.field_id,
          type_projet_id: localStorage.getItem('typeProject'),
          projet_id: this.input.projet_id
        })
      }
    },
    async saveCombobox() {
      let value = this.valueTags[this.valueTags.length - 1]
      if (value && value.id == null) {
        let matchingOption = this.getOptionsFieldTag
          .filter(tag => tag.label.trim() === value.text.trim())
          .map(v => {
            return { text: v.label, id: v.id, color: '#5C2DD3' }
          })
        if (matchingOption && matchingOption.length) {
          this.valueTags = [
            ...this.valueTags.filter(tag => tag.id != null),
            ...matchingOption
          ]
          const existingLabels = this.valueTags.map(tag => tag.text.trim())
          this.itemsTags = this.getOptionsFieldTag
            .filter(v => !existingLabels.includes(v.label.trim()))
            .map(v =>
              JSON.stringify({ text: v.label, id: v.id, color: '#5C2DD3' })
            )
        } else {
          let elementObject = this.valueTags[this.valueTags.length - 1]
          if (elementObject && elementObject.id == null) {
            await this.addCustomFieldCombobox(elementObject)
          }
          this.handleErrors(this.input.field_id, this.getCustomFieldsError)
        }
      }
      this.$nextTick(() => {
        const stringIds = this.valueTags
          .filter(tag => tag.text !== null)
          .map(tag => tag.text)
        if (
          this.value &&
          this.value.length &&
          stringIds &&
          stringIds.length &&
          this.areArraysEqual(stringIds, this.value)
        ) {
          this.fetchItemTags()
        } else {
          this.$emit('save', stringIds)
          this.$emit('disabled')
        }
      })
    },
    areArraysEqual(arr1, arr2) {
      // // Si les longueurs sont différentes, elles ne peuvent pas être égales
      // if (arr1.length !== arr2.length) {
      //   return false
      // }

      // Copiez les tableaux pour éviter de modifier les tableaux d'origine
      let sortedArr1 = arr1.slice().sort()
      let sortedArr2 = arr2.slice().sort()

      // Comparez chaque élément après avoir trié les tableaux
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false
        }
      }

      // Si tous les éléments sont égaux, les tableaux sont égaux
      return true
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    },
    async deletedChipCombobox(event, chip_id) {
      event.stopPropagation()
      const reponse = await this.deleteCustomFieldsOptions(chip_id)
      if (reponse) {
        this.fetchItemTags()
      }
    },
    // END TAGS
    handlecheckAll() {
      this.options.map(item => {
        item.checked = this.checkAll
        this.itemToCheck(item)
      })
    },
    itemToCheck(item) {
      if (item.checked == true) {
        this.tableItemCheck.push(item)
      } else {
        this.tableItemCheck = this.tableItemCheck.filter(
          item => item.checked == true
        )
      }
      //  RFRESH SELECTED ALL
      this.$nextTick(() => {
        if (this.tableItemCheck && this.tableItemCheck.length) {
          this.checkAll = true
        } else {
          this.checkAll = false
        }
        this.handleMultiselectSet(this.tableItemCheck)
      })
    },
    formatDateInput(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      if (this.input.formatDate == true) {
        return `${day}/${month}/${year}`
      } else {
        return `${year}/${month}/${day}`
      }
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    ajouterInputList() {
      if (this.input.name === 'emails') {
        this.options.push({ email: '', typeE: 'Personnel' })
      } else {
        this.options.push({ number: '', typeP: 'Travail' })
      }
    },
    removeInputList(index) {
      this.options.splice(index, 1)
    },
    handleInput(e) {
      let value = e && e.target && e.target.value ? e.target.value.trim() : e
      if (value != this.value) {
        this.$emit('save', value)
        this.$emit('disabled')
      }
    },
    handleInputNumber: debounce(function(e) {
      this.handleInput(e)
    }, 400),

    handleTimePickerRange() {
      this.selectedTimeRange = [this.selectedTimeStart, this.selectedTimeEnd]
      if (this.selectedTimeStart && this.selectedTimeEnd) {
        this.$emit('save', this.selectedTimeRange)
        this.$emit('disabled')
      }
    },
    appelTimePicker() {
      const now = new Date()
      const hours = now.getHours()
      const minutes = now.getMinutes()
      // const seconds = now.getSeconds()
      // :${seconds.toString().padStart(2, '0')
      this.selectedTime =
        this.selectedTime == null
          ? `${hours.toString().padStart(2, '0')}:${minutes
              .toString()
              .padStart(2, '0')}`
          : this.selectedTime
      this.handleTimePicker(this.selectedTime)
      this.$refs['dateDynamicImmo'].focus()
    },
    closeMenuTime(event) {
      if (!event) {
        this.handleTimePicker(this.selectedTime)
      }
    },
    handleTimePicker: debounce(function(time) {
      this.selectedTime = time
      this.$emit('save', this.selectedTime)
      this.$emit('disabled')
    }, 400),
    handleResetFilter(ref_1, ref_2, ref_3) {
      if (ref_1) {
        this[ref_1] = null
      }
      if (ref_2) {
        this[ref_2] = null
      }
      if (ref_3) {
        this[ref_3] = null
      }
    },
    handleDateTimePicker() {
      this.$emit('disabled')
      // this.$emit('save', this.selectedDateTime)
    },
    handleDatePickerRange(data) {
      this.$emit('save', data.join(', '))
      this.$emit('disabled')
    },
    handleDatePicker(data) {
      this.$emit('save', moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY'))
      this.$emit('disabled')
    },
    handleDatePickerClear() {
      this.$emit('save', ' ')
      this.$emit('disabled')
    },
    handleInputList(data, key, name) {
      let tabValue = [...this.options]
      for (let index = 0; index < tabValue.length; index++) {
        if (name === 'phones') {
          if (key == 'numberPhone') {
            tabValue[index].number = data
              .replace(/[^0-9]/g, '')
              .replace(/(\..*)\./g, '$1')
          } else {
            tabValue[index].typeP = 'Travail'
          }
          this.$emit('save', tabValue)
          this.$emit('disabled')
          tabValue = []
        }
        if (name === 'emails') {
          if (key == 'email') {
            tabValue[index].email = data
          } else {
            tabValue[index].typeE = 'Personnel'
          }
          this.$emit('save', tabValue)
          this.$emit('disabled')
          tabValue = []
        }
      }
    },
    handleMultiselectEnum(data) {
      if (data) {
        this.$emit('input', data.label)
      }
    },
    handleMultiselectTable(data) {
      this.$emit('input', data.id)
    },
    handleRadio(data) {
      this.$emit('save', data)
      this.$emit('disabled')
    },
    async appelRouteDynamic() {
      if (this.input.links && this.input.links.length) {
        for (let index = 0; index < this.input.links.length; index++) {
          await this.fetchProjectRouteDynamic(this.input.links[index].value)
        }
        if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
          this.options = this.getProjectRouteDynamic.map(t => {
            if (t.full_name) {
              return { label: t.full_name, id: t.id }
            } else {
              return { label: t.name, id: t.id }
            }
          })
          if (this.value) {
            this.valueOptions = this.options.find(e => {
              return e.id == this.value.id
            })
          }
        }
      } else if (this.input.valuePossible && this.input.valuePossible.length) {
        this.options = this.input.valuePossible.map(t => {
          return {
            label: t.name,
            id: t.id
          }
        })
        this.valueOptions = this.value
      }
    },
    handleSelectSetsClear() {
      this.valueOptions = null
      this.$emit('save', this.valueOptions)
      this.$emit('disabled')
    },
    updateOptionsDisabled() {
      this.options.forEach(option => {
        option.disabled = this.isUpdating
      })
    },
    handleMultiselectSet: debounce(function(data) {
      // If there is a modification in the detail modal, do not trigger loading.
      this.isUpdating = this.input.debounceSelectMultipe ? false : true
      this.appelRouteDynamic()
      this.valueOptions = data
      let dataMultipleSelect = null
      if (data && data.length && Array.isArray(data)) {
        dataMultipleSelect = data.map(item =>
          item && item.id ? item.id : item && item.value ? item.value : item
        )
      } else {
        dataMultipleSelect =
          data && data.id ? data.id : data && data.value ? data.value : data
      }
      this.updateOptionsDisabled() // Mettre à jour la propriété 'disabled' de chaque option
      // Émettre l'événement 'save' avec les données multiples sélectionnées.
      this.$emit('save', dataMultipleSelect)
      this.$emit('disabled')
      this.$nextTick(() => {
        setTimeout(() => {
          this.isUpdating = false
          this.updateOptionsDisabled() // Mettre à jour la propriété 'disabled' de chaque option
        }, 2000)
      })
    }, 600),
    updateDateTime() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.selectedDateTime = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      } else if (this.selectedDateTimeDate && !this.selectedDateTimeTime) {
        this.selectedDateTime = `${this.selectedDateTimeDate} 00:00`
      } else if (!this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.selectedDateTimeDate = new Date().toJSON().slice(0, 10)
        this.selectedDateTime = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      }
      this.$emit('save', this.selectedDateTime)
    }
  },
  computed: {
    ...mapGetters([
      'getProjectRouteDynamic',
      'getRouteDynamicProcessing',
      'getOptionsFieldTag',
      'getCustomFieldsError',
      'getCustomFieldsLoading'
    ]),
    computedEnumValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.label == this.value) {
          value = option
        }
      })
      return value
    },
    computedTableValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.id == this.value) {
          value = option
        }
      })
      return value
    },
    computedSetValue: function() {
      const value = []
      if (!this.value || !Array.isArray(this.value)) {
        return value
      }
      this.input.options.forEach(option => {
        if (this.value.find(v => v == option.label)) {
          value.push(option)
        }
      })
      return value
    }
  },
  watch: {
    selectedDateTimeDate() {
      this.updateDateTime()
    },
    selectedDateTimeTime() {
      this.updateDateTime()
    },
    selectedTime() {
      this.$emit('save', this.selectedTime)
    },
    value(val) {
      this.valueOptions = val
    }
  },
  created() {
    this.updateDateTime()
  },
  filters: {
    time: function(value) {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('LT DD MMM')
    }
  },
  async mounted() {
    // -------------------------------- input complex pour phone and email ---------------------------
    if (this.input.name === 'phones' || this.input.name === 'emails') {
      if (!this.value || !this.value.length) {
        if (this.input.name === 'phones') {
          this.options = [{ number: '', typeP: 'Travail' }]
        } else {
          this.options = [{ email: '', typeE: 'Personnel' }]
        }
      }
      if (this.value && this.value.length) {
        this.options = this.value
      }
    }
    //------------------------------ type  option one ou multiple --------------------------------
    if (this.input.selected) {
      if (this.input.selected == 'one') {
        this.selectedOptions = false
      } else {
        this.selectedOptions = true
      }
    }
    // ------------------------------------- input radio + input select choix one option -----------------------------------
    if (
      (this.input.type === 'radio_oui_non' || this.input.type === 'radio') &&
      this.input.valuePossible &&
      this.input.valuePossible.length
    ) {
      if (this.input.type_options == 'one_select') {
        this.options = this.input.valuePossible.map(t => {
          return {
            label: t.name,
            id: t.id
          }
        })
      } else {
        this.options = this.input.valuePossible.map(t => {
          return {
            text: t.name,
            value: t.id
          }
        })
      }
      this.valueOptions = this.value
    }
    if (this.input.type === 'radio_oui_non') {
      this.options = this.input.options.map(t => {
        return {
          text: t.name,
          value: t.id
        }
      })
      this.valueOptions = this.value == 1 || this.value == 'Oui' ? 1 : 0
    }
    // ----------------------------------------- input select (multi/one) + checkbox ----------------------
    if (this.input.type == 'multiple_select') {
      if (this.input.links && this.input.links.length) {
        for (let index = 0; index < this.input.links.length; index++) {
          await this.fetchProjectRouteDynamic(this.input.links[index].value)
        }
        if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
          this.options = this.getProjectRouteDynamic.map(t => {
            if (t.full_name) {
              return { label: t.full_name, id: t.id, disabled: false }
            } else {
              return { label: t.name, id: t.id, disabled: false }
            }
          })
          if (this.value) {
            this.valueOptions = this.options.find(e => {
              return e.id == this.value.id
            })
          }
        }
      }
      if (this.input.valuePossible && this.input.valuePossible.length) {
        // checkbox
        if (this.input.type_options == 'checkbox') {
          // Mapper les options avec la vérification du statut de chaque option
          this.options = this.input.valuePossible.map(option => ({
            label: option.name,
            id: option.id,
            checked: this.value && this.value.includes(option.id)
          }))
          // Remplir le tableItemCheck
          this.tableItemCheck = this.options.filter(
            item => item.checked == true
          )
          // Tester si au moins une option est cochée
          this.checkAll =
            this.value &&
            this.value.length &&
            this.input.valuePossible.some(option =>
              this.value.includes(option.id)
            )
        }
        // input select
        else {
          this.options = this.input.valuePossible.map(option => {
            return {
              label: option.name,
              id: option.id,
              disabled: false
            }
          })
          if (this.input.selected == 'multiple') {
            //multilpe
            let arrayOfValues = []
            this.valueOptions = this.options.filter(t => {
              if (this.value && this.value.length) {
                this.value.map(item => {
                  if (item == t.label) {
                    arrayOfValues.push({
                      label: item,
                      id: item
                    })
                  }
                })
              }
            })
            this.valueOptions = arrayOfValues
          } else {
            //one
            this.valueOptions = this.options.find(t => {
              return t.label == this.value
            })
          }
        }
      }
    }
    // --------------------------------------- input date -----------------------------------
    if (this.input.type == 'date' && this.value) {
      this.selectedDate = moment(this.value.split(' ')[0], 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      )
    }
    // --------------------------------------- input date range -------------------------------
    if (this.input.type == 'daterange' && this.value) {
      this.selectedDate = this.value.split(',').map(date => date.trim())
    }
    // ------------------------------------- input date time --------------------------------
    if (this.input.type == 'datetime' && this.value) {
      this.selectedDateTime = `${this.value}`
      this.selectedDateTimeTime = `${this.value.split(' ')[1]}`
      this.selectedDateTimeDate = `${this.value.split(' ')[0]}`
    }
    // ------------------------------------- input time --------------------------------------
    if (this.input.type == 'time' && this.value) {
      this.selectedTime = this.value
    }
    // ------------------------------------- input simple-------------------------------------
    if (
      this.input.type == 'text' ||
      this.input.type == 'integer' ||
      this.input.type == 'double' ||
      this.input.type == 'number' ||
      this.input.type == 'string'
    ) {
      this.valueText = this.value
    }
    // --------------------------------------- input tag ------------------------------
    if (this.input.type == 'tag') {
      this.resetErrorCustomField()
      if (localStorage.getItem('typeProject')) {
        this.fetchItemTags()
      }
    }
  }
}
</script>
<style lang="scss">
.input-chips-select-immo {
  .v-chip.v-size--small {
    border-radius: 3px !important;
    height: 26px !important;
  }
  .v-chip--select {
    background-color: #5b2ecf !important;
    border: 1px solid #5b2ecf !important;
    color: #fff !important;
  }
  .mdi-close-circle::before {
    content: '\2716' !important;
  }
  .v-chip--select .v-chip__close {
    background-color: #5c2dd3 !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  .v-chip .v-chip__content {
    font-size: 12px !important;
    font-weight: 600;
    line-height: 18.2px;
    font-family: 'Montserrat', sans-serif;
  }
  .theme--light.v-label {
    color: rgba(0, 0, 0, 0.6);
  }
}
.input-text-height-standard {
  .v-input__control {
    .v-input__slot {
      min-height: 30px !important;
    }
  }
  .theme--light.v-label {
    color: rgba(0, 0, 0, 0.6);
  }
}
.input-select-height-standard {
  .v-input__append-inner {
    margin-top: 4px !important;
  }
  .v-input__control {
    .v-input__slot {
      min-height: 33px !important;
    }
  }
  .theme--light.v-label {
    color: rgba(0, 0, 0, 0.6);
  }
}
.input-date-height-standard {
  .v-input__control {
    .v-input__slot {
      min-height: 33px !important;
    }
  }
  .v-input__icon {
    .v-icon {
      font-size: 21px;
    }
  }
  .v-text-field__slot {
    input {
      padding: 15px 0 8px !important;
    }
  }
  .v-input__append-inner {
    margin-top: 6px !important;
  }
  .theme--light.v-label {
    color: rgba(0, 0, 0, 0.6);
  }
}
.radio-dynamique {
  .v-label {
    font-size: 12px !important;
  }
}
.text-area-dynamique {
  font-size: 13px !important;
}
.input-combobox {
  .v-chip--select {
    background-color: #5c2dd3 !important;
    border: 1px solid #5c2dd3 !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    margin-right: 1px !important;
    text-transform: capitalize !important;
    margin-top: 4px !important;
  }
  .v-list-item {
    cursor: pointer !important;
  }
  .v-input__slot {
    box-shadow: unset !important;
  }
  .v-select__selections {
    padding: 4px 0 !important;
  }
  .v-chip--label {
    border-radius: 20px !important;
  }
}
.text-input-combobox {
  cursor: pointer !important;
}
.action-btn-delete-combobox {
  margin: 6px 0 !important;
}
</style>
