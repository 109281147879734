<template>
  <div class="editable-input" :style="{ width: styleWidthInput }">
    <div class="edit-view">
      <div
        v-if="!dragAndDropField"
        class="icon-move column-drag-container mr-1"
        :class="{ 'ml-1': !dragAndDropField }"
      >
        <font-awesome-icon
          v-if="dragAndDropEnabled"
          :icon="['fas', 'grip-dots-vertical']"
          class="img-h-18 color-icon"
        />
      </div>
      <div
        class="input-dynamic"
        :class="{
          'mr-3': dragAndDropField && !styleInputStandard,
          'mr-1': !dragAndDropField
        }"
      >
        <dynamic-input-vuetify-immo
          :input="{
            projet_id: entity && entity.id ? entity.id : null,
            field_id: field.id,
            disabledInput: !readonly && !fieldKeyEdit ? false : true,
            type: readonly || fieldKeyEdit ? 'text' : field.input_type,
            type_options: field.type,
            options: field.options ? field.options.data : [],
            name: field.key,
            label: field.name,
            links: field.links ? field.links : [],
            selected: field.selection_type,
            valuePossible: field.possible_values
              ? field.possible_values.data
              : [],
            formatDate: field.format ? true : false,
            styleComponents: styleComponents,
            debounceSelectMultipe: dragAndDropField,
            styleInputStandard: styleInputStandard
          }"
          :value="newValue"
          v-model="newValue"
          @save="update"
          @disabled="disabled"
        ></dynamic-input-vuetify-immo>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicInputVuetifyImmo from '@/components/ui/dynamic-form/dynamic-input-vuetify-immo.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'EditableCustomFieldCategorieInfoImmo',
  components: {
    dynamicInputVuetifyImmo
  },
  props: {
    dragAndDropEnabled: {
      type: Boolean,
      required: false
    },
    field: { required: true },
    entity: { required: false },
    value: { required: false },
    readonly: { default: false },
    fieldKeyEdit: { required: false },
    styleWidthInput: { required: false },
    subCategory: { required: false },
    category: { required: false },
    oneSubCat: { required: false },
    styleComponents: { required: false },
    dragAndDropField: { required: false },
    styleInputStandard: { required: false }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  mounted() {
    this.newValue = this.value
    if (this.field.input_type === 'table') {
      if (this.field.table_name === 'users') {
        this.field.options.data = this.usersList
      }
    }
  },
  watch: {
    value(val) {
      this.newValue = val
      this.$emit('disabled')
    }
  },
  methods: {
    update(value) {
      this.newValue = value
      //  partie modification fields pour information
      if (!this.styleInputStandard) {
        // Construisez l'objet payload avec les champs mis à jour
        let mappingFields = []
        if (this.dragAndDropField) {
          this.subCategory.map(subCategory => {
            return {
              ...subCategory,
              fields: subCategory.fields.map(field => {
                // not champ entete
                if (field.is_header !== 1) {
                  // Mettez à jour les valeurs des champs pour chaque catégorie
                  if (JSON.stringify(field) === JSON.stringify(this.field)) {
                    this.updateFieldValuesNull(
                      subCategory.fields,
                      this.field.key,
                      value
                    )
                  }
                  const valueField =
                    field.value && field.value.id ? field.value.id : field.value

                  mappingFields.push({
                    field: {
                      [field.key]:
                        valueField == null
                          ? field.lastValue && field.lastValue.id
                            ? field.lastValue.id
                            : field.lastValue
                          : valueField
                    },
                    object: field.object
                  })
                }
                return field
              })
            }
          })
          // FIELDS CONTACT
          // const fieldContact = this.extractFieldsByObject(
          //   mappingFields,
          //   'contacts'
          // )
          // FIELDS PROJET
          const fieldProjet = this.extractFieldsByObject(
            mappingFields,
            'projets'
          )
          // Construisez l'objet payload final
          const payload = {
            entity: this.entity,
            fieldProjet
          }
          // Émettez l'événement avec l'objet payload
          this.$emit('updateFunction', payload)
        } else {
          // not champ entete
          if (this.field.is_header !== 1) {
            // Construisez l'objet payload final
            const payload = {
              entity: this.entity,
              field: this.field.key,
              value: this.newValue,
              object: this.field.object
            }
            // Émettez l'événement avec l'objet payload
            this.$emit('updateFunction', payload)
          }
        }
      }
      // partie pour ajouter nouvelle opportinuty
      else {
        // Construisez l'objet payload avec les champs mis à jour
        let mappingFields = []
        this.subCategory.map(field => {
          // Mettez à jour les valeurs des champs pour chaque catégorie
          if (JSON.stringify(field) === JSON.stringify(this.field)) {
            this.updateFieldValuesNull(this.subCategory, this.field.key, value)
          }
          const valueField =
            field.value && field.value.id ? field.value.id : field.value

          mappingFields.push({
            field: {
              field_id: field.id,
              value:
                valueField == null
                  ? field.lastValue && field.lastValue.id
                    ? field.lastValue.id
                    : field.lastValue
                  : valueField
            },
            object: field.object
          })

          return field
        })

        // FIELDS CONTACT
        // const fieldContact = this.extractFieldsByObject(
        //   mappingFields,
        //   'contacts'
        // )
        // FIELDS PROJET
        const fieldProjet = this.extractFieldsByObject(mappingFields, 'projets')
        // Construisez l'objet payload final
        // const payload = {
        //   entity: this.entity,
        //   field: mappingFields,
        //   fieldContact,
        //   fieldProjet
        // }
        // Émettez l'événement avec l'objet payload
        this.$emit('updateFunction', fieldProjet)
      }
    },
    // Fonction pour mettre à jour les valeurs des champs
    updateFieldValues(fields, newValue) {
      if (
        fields.input_type === 'timerange' ||
        fields.input_type === 'daterange'
      ) {
        fields.value = newValue.join('/')
      } else {
        fields.value = newValue
      }
    },
    // Fonction pour mettre à jour la valeur d'un champ
    updateFieldValuesNull(fields, key, newValue) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].key === key) {
          // Stockez la dernière valeur du champ
          fields[i].lastValue = fields[i].value
          // Mettez à jour la valeur du champ
          fields[i].value = newValue
          break
        }
      }
    },
    // Fonction pour extraire les champs en fonction de l'objet
    extractFieldsByObject(mappingFields, objectName) {
      return mappingFields
        .filter(item => item.object === objectName)
        .map(item => ({ ...item.field }))
    },
    disabled() {
      this.$emit('disabledBtnSave')
    }
  },
  computed: {
    ...mapGetters(['getProjectsUsers']),
    usersList: function() {
      return this.getProjectsUsers.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.editable-input {
  .edit-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.input-radio {
      border: 1px solid #9e9e9e;
      padding: 8px;
      width: 100%;
      border-radius: 4px;
    }
    .input-dynamic {
      width: 100%;
    }
    .icon-move {
      position: relative;
      left: -2px;
      top: -3px;
      .color-icon {
        color: #7f8c8d !important;
      }
    }
    .check-visible-imo-modal {
      // position: relative;
      // right: -2px;
      // top: 9px;
    }
  }
}
</style>
